import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
// import MySwiper from '../components/Swiper'
// import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

class IndexPage extends React.Component {
	render() {
		const siteTitle = this.props.data.site.siteMetadata.title
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div id="index">
					<section id="about">
						<div className="container">
							<div className="row">
								<div className="col-md-7">
									<img
										className="img-fluid"
										src="/img/hero-bg-3.jpg"
										alt="about us"
									/>
								</div>
								<div className="col-sm-12 col-md-5">
									<div className="title">
										<h2>About Us</h2>
										<span className="line gradient-color" />
									</div>
									<p>
										EthosOpp is a Women-Owned Alternative staffing firm
										dedicated to providing Workforce Solutions to any
										organization.
									</p>
									<p>
										Regardless of the industry, we will help you be the best
										organization you can be. Our mission is to provide
										businesses with industry best practices and efficiencies to
										ensure sustainability and cost savings. Whether it's
										assisting in recruiting and retention efforts, providing
										Human Resources guidance or mitigating risk with Independent
										Contractor compliance, we are here for you. Your employees
										are your best asset, be sure you know what you need to know.
									</p>
									<p>Some of the services we help with:</p>
									<ul className="about-list">
										<li>
											<i className="fa fa-check" />
											&nbsp;Human Resources
										</li>
										<li>
											<i className="fa fa-check" />
											&nbsp;Risk Management
										</li>
										<li>
											<i className="fa fa-check" />
											&nbsp;Recruitment/Placement
										</li>
										<li>
											<i className="fa fa-check" />
											&nbsp;Talent Management
										</li>
										<li>
											<i className="fa fa-check" />
											&nbsp;Independent Contractor Risk Mitigation
										</li>
										<li>
											<i className="fa fa-check" />
											&nbsp;Process Review/Improvement
										</li>
									</ul>
									<div className="btn-box mt-4">
										<Link
											to="/about-us"
											title="About Us Page"
											className="btn-sm btn-lg btn-blue"
										>
											Read More
										</Link>
									</div>
								</div>
								<div className="col-sm-12 col-md-6 d-none">
									<img
										className="img-fluid about-right-img"
										src="/img/adult-employee-facial-expression.jpg"
										alt="Adult Employee Facial Expression"
									/>
									<img
										className="img-fluid about-right-img"
										src="/img/architecture-building-city.jpg"
										alt="Architecture Building"
									/>
								</div>
							</div>
						</div>
					</section>
					<section id="mission-vision">
						<div className="container-fluid">
							<div className="row">
								<div className="col-sm-12 col-md-6 col-item">
									<div className="area-1 p-50">
										<div className="title">
											<h3>Mission</h3>
										</div>
										<p>
											EthosOpp's diverse group of professionals are committed to
											hard work, accountability, customer service and above all,
											RESULTS. No one will work harder for their clients,
											period!
										</p>
									</div>
								</div>
								<div className="col-sm-12 col-md-6 col-item">
									<div className="area-2 p-50">
										<div className="title">
											<h3>Vision</h3>
										</div>
										<p>
											We are leaders who are committed to a future of
											possibilities. We create opportunities for companies to
											grow, improve and prosper. Hiring for good, helping
											companies thrive and promoting positive change is who we
											are.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="services">
						<div className="container">
							<div className="title text-center">
								<h2>Awesome Services</h2>
								<span className="line gradient-color" />
							</div>
							<div className="row mt-5">
								<div className="col-sm-12 col-md-3">
									<Link
										to="/recruitment"
										className="text-dark"
										title="Recruitment and HR Consulting"
									>
										<div className="card">
											<div className="card-header text-center">
												<img
													className="img-fluid service-icon"
													src="/img/solution.svg"
													alt="Recruitment and HR Consulting"
												/>
											</div>
											<div className="card-body">
												<h5 className="card-title">HR Consulting</h5>
											</div>
										</div>
									</Link>
								</div>
								<div className="col-sm-12 col-md-3">
									<Link
										to="/operations"
										className="text-dark"
										title="Operations review, documentation and improvement"
									>
										<div className="card">
											<div className="card-header text-center">
												<img
													className="img-fluid service-icon"
													src="/img/review.svg"
													alt="Operations review, documentation and improvement"
												/>
											</div>
											<div className="card-body">
												<h5 className="card-title">
													Operations review, documentation and improvement
												</h5>
											</div>
										</div>
									</Link>
								</div>
								<div className="col-sm-12 col-md-3">
									<Link
										to="/employment"
										className="text-dark"
										title="Employment Solutions"
									>
										<div className="card">
											<div className="card-header text-center">
												<img
													className="img-fluid service-icon"
													src="/img/recruitment.svg"
													alt="Employment Solutions"
												/>
											</div>
											<div className="card-body">
												<h5 className="card-title">Employment Solutions</h5>
											</div>
										</div>
									</Link>
								</div>
								<div className="col-sm-12 col-md-3">
									<Link
										to="/enterprise"
										className="text-dark"
										title="Employment Solutions"
									>
										<div className="card">
											<div className="card-header text-center">
												<img
													className="img-fluid service-icon"
													src="/img/work.svg"
													alt="Social Enterprise"
												/>
											</div>
											<div className="card-body">
												<h5 className="card-title">
													Social Enterprise, hiring for good
												</h5>
											</div>
										</div>
									</Link>
								</div>
							</div>
						</div>
					</section>
					{/*<div id="testimonials" className="testimonials-clean">
						<div className="container">
							<div className="title">
								<h2 className="text-center">Testimonials </h2>
								<span className="line gradient-color" />
							</div>
							<MySwiper>
								<div>
									<div className="item">
										<div className="box">
											<p className="description">
												You do what you do best, let us do what we do best Think
												Different, Hire Different, Be Different, We improve your
												bottom line, by taking care of your front line
											</p>
										</div>
										<div className="author">
											<img
												className="rounded-circle"
												alt="testimonials"
												src="/img/1.jpg"
											/>
											<h5 className="name">Ben Johnson</h5>
											<p className="title">CEO of Company Inc.</p>
										</div>
									</div>
								</div>
								<div>
									<div className="item">
										<div className="box">
											<p className="description">
												You do what you do best, let us do what we do best Think
												Different, Hire Different, Be Different, We improve your
												bottom line, by taking care of your front line
											</p>
										</div>
										<div className="author">
											<img
												className="rounded-circle"
												alt="testimonials"
												src="/img/3.jpg"
											/>
											<h5 className="name">Carl Kent</h5>
											<p className="title">Founder of Style Co.</p>
										</div>
									</div>
								</div>
								<div>
									<div className="item">
										<div className="box">
											<p className="description">
												You do what you do best, let us do what we do best Think
												Different, Hire Different, Be Different, We improve your
												bottom line, by taking care of your front line
											</p>
										</div>
										<div className="author">
											<img
												className="rounded-circle"
												alt="testimonials"
												src="/img/2.jpg"
											/>
											<h5 className="name">Emily Clark</h5>
											<p className="title">Owner of Creative Ltd.</p>
										</div>
									</div>
								</div>
							</MySwiper>
						</div>
					</div>*/}
				</div>
			</Layout>
		)
	}
}

export default IndexPage

export const indexPageQuery = graphql`
	query indexPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
